import {
  Box,
  Button,
  Card,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createAccountClient, createAccountCoach } from '../api';
import { ConfirmationPopup } from '../components/common';
import { IF } from '../components/core';
import {
  CapitalRegex,
  EmailRegex,
  isEmptyOrIsNull,
  LowerRegex,
  NumberRegex,
  PasswordRegex,
  URLRegex,
  USER_TYPE,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 50,
    maxWidth: 488,
    width: '100%',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(7, 5),
    borderRadius: 5,
    boxShadow: 'none',
  },
  signInText: {
    font: "500 26px/30px 'Roboto'",
    padding: theme.spacing(0, 3),
    color: '#ffffff',
  },
  signInBtn: {
    background: '#5f5f5f',
    color: theme.palette.common.white,
    padding: theme.spacing(1.4, 0),
    boxShadow: 'none',
    borderRadius: 2,
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
      boxShadow: 'none',
    },
  },
  inputFieldContainer: {
    padding: theme.spacing(0, 3),
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#9D9D9D',
    },
  },
  inputField: {
    background: theme.palette.common.white,
    borderRadius: 2,
    '&:hover': {
      background: '#eeeeee',
    },
    '&,.MuiInputBase-input:focus': {
      background: theme.palette.common.white,
    },
  },
  inputLabel: {
    color: '#9D9D9D',
  },
  helperText: {
    color: '#FF5F58',
    marginLeft: theme.spacing(-0.2),
    fontStyle: 'italic',
  },
  apiErrorMsg: {
    padding: theme.spacing(0, 3),
  },
  invalidPassword: {
    color: '#ff5f58',
  },
  validPassword: {
    color: '#ffffff',
  },
}));

export const CreateAccount: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [criteriaObj, setCriteriaObj] = useState({
    email: '',
    firstName: '',
    name: '',
    userName: '',
    password: '',
    confirmPassword: '',
    url: '',
    apiKey: '',
  });
  const [errorObj, setErrorObj] = useState<any>({
    email: '',
    firstName: '',
    name: '',
    userName: '',
    password: '',
    confirmPassword: '',
    url: '',
    apiKey: '',
  });
  const [userType, setUserType] = useState<string | null>('');
  const [apiErrorMsg, setApiErrorMsg] = useState('');

  useEffect(() => {
    const type = localStorage.getItem(USER_TYPE);
    if (isEmptyOrIsNull(type)) history.push('/');

    setUserType(type);
  }, []);

  const validateEmail = (email: string) => EmailRegex.test(email.toLowerCase());
  const validateURL = (url: string) => URLRegex.test(url.toLowerCase());
  const validatePassword = (password: string) => PasswordRegex.test(password);

  const getValidPasswordMessage = (value: any) => {
    return (
      <>
        <span
          className={`${
            value.toString().length < 8
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`Min. 8 Zeichen; `}
        </span>
        <span
          className={`${
            !CapitalRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Großbuchstabe; `}
        </span>
        <span
          className={`${
            !LowerRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Kleinbuchstabe; `}
        </span>
        <span
          className={`${
            !NumberRegex.test(value)
              ? classes.invalidPassword
              : classes.validPassword
          }`}
        >
          {`1 Zahl`}
        </span>
      </>
    );
  };

  const validateForm = () => {
    let errorList: any = {};

    if (isEmptyOrIsNull(criteriaObj.email)) {
      errorList.email = 'Bitte geben Sie Ihre E-Mail Adresse an';
    } else if (!validateEmail(criteriaObj.email)) {
      errorList.email =
        'Bitte geben Sie die Mail-Adresse ein, an die wir den Einladungs-Code gesendet haben';
    }

    if (isEmptyOrIsNull(criteriaObj.firstName)) {
      errorList.firstName = 'Bitte geben Sie Ihren Vornamen an';
    }

    if (isEmptyOrIsNull(criteriaObj.name)) {
      errorList.name = 'Bitte geben Sie Ihren Nachnamen an';
    }
    if (isEmptyOrIsNull(criteriaObj.userName)) {
      errorList.userName = 'Bitte geben Sie einen Benutzernamen an';
    }
    if (isEmptyOrIsNull(criteriaObj.password)) {
      errorList.password = 'Bitte geben Sie ein Passwort ein';
    } else {
      errorList.password = getValidPasswordMessage(criteriaObj.password);
    }
    if (isEmptyOrIsNull(criteriaObj.confirmPassword)) {
      errorList.confirmPassword = 'Bitte wiederholen Sie das Passwort';
    }
    if (
      !isEmptyOrIsNull(criteriaObj.password) &&
      !isEmptyOrIsNull(criteriaObj.confirmPassword) &&
      criteriaObj.password !== criteriaObj.confirmPassword
    ) {
      errorList.confirmPassword = 'Passwörter müssen identisch sein';
    }
    if (userType === 'Coach') {
      if (isEmptyOrIsNull(criteriaObj.url)) {
        errorList.url = 'URL ist erforderlich';
      } else if (!validateURL(criteriaObj.url)) {
        errorList.url =
          'Bitte geben Sie die URL in folgendem Format ein: https://google.de/';
      }
    }

    if (
      Object.keys(errorList).length === 1 &&
      validatePassword(criteriaObj.password)
    ) {
      setErrorObj(errorList);
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const updateValue = (key: any, value: any) => {
    let errorList: any = { ...errorObj };
    setCriteriaObj({ ...criteriaObj, [key]: value });
    if (key === 'password') {
      if (isEmptyOrIsNull(value)) {
        errorList.password = 'Bitte geben Sie ein Passwort ein';
      } else {
        errorList.password = getValidPasswordMessage(value);
      }
      setErrorObj({ ...errorList });
    }
  };

  const onBlur = () => {
    validateForm();
  };

  const handelClearRePassword = () => {
    setCriteriaObj({ ...criteriaObj, confirmPassword: '' });
    setErrorObj({ ...errorObj, confirmPassword: '' });
  };

  const handelCleanMail = () => {
    setCriteriaObj({ ...criteriaObj, email: '' });
    setErrorObj({ ...errorObj, email: '' });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;
    setApiErrorMsg('');

    if (userType === 'Coach') {
      if (criteriaObj.apiKey) {
        submitAccount();
      } else {
        setConfirmationOpen(true);
      }
    } else {
      submitAccount();
    }
  };

  const submitAccount = async () => {
    setConfirmationOpen(false);
    const payload = {
      email: criteriaObj.email,
      first_name: criteriaObj.firstName,
      last_name: criteriaObj.name,
      nickname: criteriaObj.userName,
      password: criteriaObj.password,
      url: criteriaObj.url,
      api: criteriaObj.apiKey,
    };
    if (userType === 'Coach') {
      try {
        const res = await createAccountCoach(payload);
        history.push('/das-wars');
      } catch (err: any) {
        setApiErrorMsg(err.response.data.detail);
      }
    } else {
      try {
        const res = await createAccountClient(payload);
        history.push('/das-wars');
      } catch (err: any) {
        setApiErrorMsg(err.response.data.detail);
      }
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <Card className={classes.container}>
          <Box className={classes.signInText} data-testid="main-text">
            Damit Sie die Plattform in vollem Umfang nutzen können, benötigen
            wir noch folgende Angaben
          </Box>
          {apiErrorMsg && (
            <Box className={classes.apiErrorMsg} mt={3}>
              <Alert severity="error">{apiErrorMsg}</Alert>
            </Box>
          )}
          <Box className={classes.inputFieldContainer} mt={5}>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  htmlFor="Mail-adresse"
                  className={classes.inputLabel}
                >
                  Mail-Adresse
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'email' }}
                  id="Mail-Adresse"
                  disableUnderline
                  autoComplete="on"
                  className={classes.inputField}
                  type="email"
                  margin="dense"
                  onChange={(event) => updateValue('email', event.target.value)}
                  onBlur={() => onBlur()}
                  error={!!errorObj.email}
                  value={criteriaObj.email}
                  endAdornment={
                    !!errorObj.email && !!criteriaObj.email ? (
                      <InputAdornment position="end">
                        <IconButton
                          data-testid="clear-email"
                          onClick={handelCleanMail}
                          edge="end"
                          style={{
                            background: '#FF5F58',
                            width: 20,
                            height: 20,
                            marginRight: 4,
                          }}
                        >
                          <ClearIcon
                            style={{ color: 'white' }}
                            fontSize="small"
                          />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.email}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="Vorname" className={classes.inputLabel}>
                  Vorname
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'firstname' }}
                  id="Vorname"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    updateValue('firstName', event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.firstName}
                  value={criteriaObj.firstName}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.firstName}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="Name" className={classes.inputLabel}>
                  Name
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'lastname' }}
                  id="Name"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) => updateValue('name', event.target.value)}
                  onBlur={() => onBlur()}
                  error={!!errorObj.name}
                  value={criteriaObj.name}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.name}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  htmlFor="Benutzername"
                  className={classes.inputLabel}
                >
                  Benutzername
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'nickname' }}
                  id="Benutzername"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    updateValue('userName', event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.userName}
                  value={criteriaObj.userName}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.userName}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="password" className={classes.inputLabel}>
                  Passwort
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'password' }}
                  id="password"
                  type="password"
                  disableUnderline
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    updateValue('password', event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.password}
                  value={criteriaObj.password}
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.password}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box mt={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel
                  htmlFor="confirmPassword"
                  className={classes.inputLabel}
                >
                  Passwort wiederholen
                </InputLabel>
                <FilledInput
                  inputProps={{ 'data-testid': 'confirmPassword' }}
                  id="confirmPassword"
                  type="password"
                  disableUnderline
                  autoComplete="on"
                  className={classes.inputField}
                  margin="dense"
                  onChange={(event) =>
                    updateValue('confirmPassword', event.target.value)
                  }
                  onBlur={() => onBlur()}
                  error={!!errorObj.confirmPassword}
                  value={criteriaObj.confirmPassword}
                  endAdornment={
                    !!errorObj.confirmPassword &&
                    !!criteriaObj.confirmPassword ? (
                      <InputAdornment position="end">
                        <IconButton
                          data-testid="clear-confirmPassword"
                          onClick={handelClearRePassword}
                          edge="end"
                          style={{
                            background: '#FF5F58',
                            width: 20,
                            height: 20,
                            marginRight: 4,
                          }}
                        >
                          <ClearIcon
                            style={{ color: 'white' }}
                            fontSize="small"
                          />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }
                />
                <FormHelperText id="helper-text" className={classes.helperText}>
                  {errorObj.confirmPassword}
                </FormHelperText>
              </FormControl>
            </Box>
            {userType === 'Coach' && (
              <Box>
                <Box mt={3}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel htmlFor="url" className={classes.inputLabel}>
                      URL
                    </InputLabel>
                    <FilledInput
                      inputProps={{ 'data-testid': 'url' }}
                      id="url"
                      disableUnderline
                      className={classes.inputField}
                      margin="dense"
                      onChange={(event) =>
                        updateValue('url', event.target.value)
                      }
                      onBlur={() => onBlur()}
                      error={!!errorObj.url}
                      value={criteriaObj.url}
                    />
                    <FormHelperText
                      id="helper-text"
                      className={classes.helperText}
                    >
                      {errorObj.url}
                    </FormHelperText>
                  </FormControl>
                  <Box mt={3}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel htmlFor="url" className={classes.inputLabel}>
                        Sendinblue API Key
                      </InputLabel>
                      <FilledInput
                        inputProps={{ 'data-testid': 'apiKey' }}
                        id="apiKey"
                        disableUnderline
                        className={classes.inputField}
                        margin="dense"
                        onChange={(event) =>
                          updateValue('apiKey', event.target.value)
                        }
                        onBlur={() => onBlur()}
                        value={criteriaObj.apiKey}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            )}
            <Box mt={3}>
              <Button
                data-testid="create-account"
                type="submit"
                variant="contained"
                fullWidth
                className={classes.signInBtn}
                size="large"
              >
                KONTO ERSTELLEN
              </Button>
            </Box>
          </Box>
        </Card>
      </form>
      <IF condition={confirmationOpen}>
        <ConfirmationPopup
          title={'Bist Du sicher?'}
          description={
            <Fragment>
              Ohne den API Key können wir Dir nicht den vollen Service von
              <br />
              Sendinblue (Mails Verwaltung) zur Verfügung stellen!
            </Fragment>
          }
          buttonClose={'abbrechen'}
          button={'Ignorieren'}
          buttonClass={'buttonRed'}
          onChange={submitAccount}
          onClose={() => setConfirmationOpen(false)}
        />
      </IF>
    </Fragment>
  );
};
