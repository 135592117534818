import { Box, Button, IconButton, makeStyles, Menu } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ConfirmationPopup, LoginLogoutButton } from '.';
import { getOwnUserAPI, hardDeleteUserAPI } from '../../api';
import { useAuthDispatch } from '../../providers';
import { UserType } from '../../types';
import { isClient, isCoach } from '../../utils';
import { IF } from '../core';
import { EditClientProfile, EditProfile } from '../shared';

const useStyles = makeStyles((theme) => ({
  loginBtnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.88,
    color: '#5f5f5f',
    textDecoration: 'none',
    marginRight: 15,
    '&:hover': {
      fontWeight: 700,
    },
  },
  iconButton: {
    marginRight: -20,
  },
  icon: {
    fontSize: 32,
    cursor: 'pointer',
  },
  menu: {
    '& .MuiMenu-list': {
      padding: 10,
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& .MuiButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  menuButtonContainer: {
    borderRadius: 4,
    '& a': {
      textDecoration: 'none',
      color: 'black',
      width: '100%',
      minWidth: '64px',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  menuButton: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '8px 10px',
    fontSize: '0.875rem',
    minWidth: '64px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
  },
  deleteButton: {
    color: '#fd5f58',
    padding: '10px !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
}));

type Props = {
  showLoginButtons: boolean;
};

export const Navigation: FC<Props> = ({ showLoginButtons }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const history = useHistory();

  const { onLogout }: any = useAuthDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(event.currentTarget);
  };

  const handleClose = () => {
    setIsOpen(null);
  };

  const handleDeleteAccount = async () => {
    setOpen(false);
    getOwnUserId();
  };

  const getOwnUserId = async () => {
    try {
      const res = await getOwnUserAPI();
      deleteAccount(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAccount = async (user: UserType) => {
    const payload = {
      id: user.id,
    };
    try {
      const res = hardDeleteUserAPI(payload);
      onLogout();
      history.push('/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Box className={classes.loginBtnContainer}>
        <IF condition={showLoginButtons}>
          <IF condition={isCoach()}>
            <Link
              className={classes.navLink}
              to={{
                pathname: '/coach/clients/',
              }}
            >
              Klienten
            </Link>
            <EditProfile />
          </IF>
          <IF condition={isClient()}>
            <Link
              className={classes.navLink}
              to={{
                pathname: '/client/dashboard',
              }}
            >
              Mein Kurs
            </Link>
            <EditClientProfile />
          </IF>
          <IconButton
            data-testid="menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.iconButton}
          >
            <MoreVertIcon className={classes.icon} />
          </IconButton>
          <Menu
            anchorEl={isOpen}
            keepMounted
            open={Boolean(isOpen)}
            onClose={handleClose}
            className={classes.menu}
            disableScrollLock={true}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <LoginLogoutButton />
            <Box className={classes.menuButtonContainer} onClick={handleClose}>
              <Link
                to={{
                  pathname: '/faqs',
                }}
              >
                <div className={classes.menuButton}>FAQs</div>
              </Link>
            </Box>
            <Button
              className={classes.deleteButton}
              onClick={() => setOpen(true)}
            >
              Konto löschen
            </Button>
            <IF condition={open}>
              <ConfirmationPopup
                title={'Bist Du sicher?'}
                description={'Dein Konto wird endgültig gelöscht'}
                buttonClose={'abbrechen'}
                button={'Konto löschen'}
                buttonClass={'buttonRed'}
                onChange={handleDeleteAccount}
                onClose={() => setOpen(false)}
              />
            </IF>
          </Menu>
        </IF>
      </Box>
    </Box>
  );
};

export default Navigation;
