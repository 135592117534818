import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import Badge from '../../../../assets/congratulationsBadge.svg';

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-root': {
      backgroundColor: '#5f5f5f',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: 60,
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  badge: {
    height: 40,
    marginLeft: 10,
  },
  closeButton: {
    backgroundColor: '#efefef',
    textTransform: 'none',
    padding: '10px 30px',
    marginRight: 20,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  confirmButton: {
    backgroundColor: '#24c776',
    padding: '10px 30px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
  congrats: {
    '& .MuiPaper-root .MuiDialogContent-root > p': {
      fontWeight: 'bold',
      fontStyle: 'normal',
    },
  },
}));

type Props = {
  onChange: Function;
  lastSubmodule: boolean;
};

export const SubmissionConfirmation: FC<Props> = ({
  onChange,
  lastSubmodule,
}) => {
  const classes = useStyles();
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);

  const handleClose1 = () => {
    setOpen1(false);
    onChange(false);
  };

  const handleSubmitTool = () => {
    setOpen1(false);
    onChange(true);
  };

  const handleSubmitModule = () => {
    setOpen1(false);
    setOpen2(true);
    onChange(true);
  };

  const handleClose2 = () => {
    setOpen1(false);
    setOpen2(false);
  };

  return (
    <div>
      <Dialog
        className={classes.popup}
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="dialog"
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            if (lastSubmodule) {
              handleSubmitModule();
            } else {
              handleSubmitTool();
            }
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" data-testid="submit-confirm-title">
          {lastSubmodule ? 'Modul abschließen' : 'Tool abschließen'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            data-testid="submit-confirm-text"
          >
            {lastSubmodule
              ? 'Du bist bereit für die nächste Session? Deine Reflexion kann dann nicht mehr bearbeitet werden.'
              : 'Bist du sicher? Deine Reflexion kann dann nicht mehr bearbeitet werden.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="close-popup"
            className={classes.closeButton}
            onClick={handleClose1}
          >
            Reflexion bearbeiten
          </Button>
          <Button
            data-testid="submit-praxis"
            className={classes.confirmButton}
            onClick={
              lastSubmodule
                ? () => handleSubmitModule()
                : () => handleSubmitTool()
            }
          >
            {lastSubmodule ? 'Modul Abschließen' : 'Tool abschließen'}
          </Button>
        </DialogActions>
      </Dialog>
      {open2 ? (
        <Dialog
          className={`${classes.popup} ${classes.congrats}`}
          open={open2}
          onClose={handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          data-testid="confirm-dialog"
          onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
              handleClose2();
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <div data-testid="congratulation">{'Glückwunsch!'}</div>
            <img className={classes.badge} src={Badge} alt="badge" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              data-testid="success-text"
            >
              {'Dieses Modul wurde erfolgreich durch Dich bearbeitet!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="ok"
              className={classes.confirmButton}
              onClick={handleClose2}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default SubmissionConfirmation;
