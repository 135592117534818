import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, Card, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 550,
    width: '100%',
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(6, 4, 6, 4),
    boxShadow: 'none',
  },
  mainText: {
    width: 350,
    color: theme.palette.common.white,
    margin: 'auto',
    font: "700 26px/30px 'Roboto'",
  },
  btnContainer: {
    width: 350,
    paddingTop: theme.spacing(4),
    margin: 'auto',
  },
  dasWarBtn: {
    background: '#5f5f5f',
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 2,
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
      boxShadow: 'none',
    },
  },
  italicText: {
    fontStyle: 'italic',
    color: '#5F5F5F',
    font: "400 18px/24px 'Roboto'",
    paddingTop: theme.spacing(1),
  },
}));

export const DasWars: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.container}>
      <Box className={classes.mainText}>
        Das war's! <br />
        Wir haben Ihnen eine E-Mail zur Bestätigung geschickt
      </Box>
      <Box className={classes.italicText}>
        Bitte checken Sie auch lhren Spam-Ordner.
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          data-testid="alles-klar"
          variant="contained"
          className={classes.dasWarBtn}
          size="large"
          fullWidth
          onClick={() => history.push('/login')}
        >
          ALLES KLAR
        </Button>
      </Box>
    </Card>
  );
};
