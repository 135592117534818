import { makeStyles, Box, List, ListItem } from '@material-ui/core';
import React, { FC } from 'react';
import { FaqItem } from '../components/common';
import { faqs } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'calc(100% - 20%)',
    padding: '0px 10% 20px',
  },
  mainHeading: {
    fontFamily: 'Roboto',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.12,
    color: '#5f5f5f',
    textAlign: 'left',
  },
  questionListItem: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
}));

export const FAQ: FC = () => {
  const classes = useStyles();

  const faqList = faqs;

  return (
    <Box className={classes.container}>
      <Box className={classes.mainHeading}>FAQs</Box>
      <List>
        {faqList.map((faqItem: any, index: number) => (
          <ListItem key={index} className={classes.questionListItem}>
            <FaqItem
              question={faqItem.question}
              answer={faqItem.answer}
            ></FaqItem>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FAQ;
