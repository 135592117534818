import { ColorType } from '../types/ColorType';

export const chartColors: ColorType = {
  first: '#fdf172',
  second: '#75e486',
  third: '#78eafa',
  fourth: '#ef7f3c',
};

export const questions = [
  {
    questionNumber: 1,
    question: '1. Ich habe eine feine Wahrnehmung für meine Umgebung.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 2,
    question:
      '2. Intensive Reize wie laute Geräusche oder chaotische Situationen stören mich.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 3,
    question: '3. Kunst oder Musik berührt mich sehr stark.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 4,
    question:
      '4. Veränderungen in meinem Leben bringen mich merklich durcheinander.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 5,
    question:
      '5. Ich werde verunsichert, wenn ich in kurzer Zeit viel zu tun habe.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 6,
    question: '6. Ich habe ein reiches, vielfältiges Innenleben.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 7,
    question:
      '7. Ich fühle mich leicht überwältigt von intensiven Reizen, wie grelles Licht, starken Gerüchen, kratzigen Stoffen oder Sirenen in meiner Nähe.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
  {
    questionNumber: 8,
    question:
      '8. Ich bemerke und geniesse zarte Gerüche, Geschmackserlebnisse, Klänge, Kunstwerke.',
    options: [
      { option: 'TRIFFT NICHT ZU', optionNumber: 1 },
      { option: 'EHER NICHT', optionNumber: 2 },
      { option: 'UNENTSCHIEDEN', optionNumber: 3 },
      { option: 'TRIFFT EHER ZU', optionNumber: 4 },
      { option: 'TRIFFT ZU', optionNumber: 5 },
    ],
  },
];

export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;

export const URLRegex =
  /^(http|https):\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)/;

export const PasswordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?^&-]{8,}$/;

export const CapitalRegex = /[A-Z]/;

export const LowerRegex = /[a-z]/;

export const NumberRegex = /[0-9]/;

export const USER_TYPE = 'user_type';

export const routesMapAfterLogin: any = {
  client: '/client/dashboard',
  coach: '/coach/clients',
  admin: '/admin-page',
  undefined: '/login',
  null: '/login',
};
