import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { ClientType } from '../../../../types/ClientType';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { deleteClient, getClients } from '../../../../api';

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-root': {
      backgroundColor: '#8e8e8e',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: 60,
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
        marginBottom: 0,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  iconClearRounded: {
    fontSize: 26,
    cursor: 'pointer',
    color: 'white',
  },
  closeButton: {
    backgroundColor: '#efefef',
    textTransform: 'none',
    padding: '10px 30px',
    marginRight: 7,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  deleteButton: {
    backgroundColor: '#fd5f58',
    padding: '10px 30px',
    textTransform: 'uppercase',
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(253,95,88,0.7)',
    },
  },
}));

type Props = {
  onChange: any;
  clients: any;
};

export const DeleteClientAlert: FC<Props> = ({ clients, onChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    setOpen(false);
    deleteClientAPI(clients.user_id);
    onChange();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteClientAPI = async (id: number) => {
    const payload = { ids: [id] };
    try {
      const res = await deleteClient(payload);
      onChange();
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div>
      <CloseRoundedIcon
        data-testid="delete-client"
        className={classes.iconClearRounded}
        onClick={handleClickOpen}
      />
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="delete-dialog"
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            handleDelete();
          }
        }}
      >
        <DialogTitle id="alert-dialog-title" data-testid="confirm-title">
          {'Bist Du sicher?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            data-testid="confirm-text"
          >
            {'Dadurch wird der Klient permanent gelöscht.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="abort"
            className={classes.closeButton}
            onClick={handleClose}
          >
            {'abbrechen'}
          </Button>
          <Button
            data-testid="erase"
            className={classes.deleteButton}
            onClick={handleDelete}
          >
            {'Löschen'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteClientAlert;
