import React, { FC, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

type Props = {
  percentageValue: number;
  color: string;
  maxSize?: number;
};

export const Circle: FC<Props> = (props) => {
  const { percentageValue = 100, color } = props;
  const classes = useStyles();
  const [size, setSize] = useState(1);
  const [height, setHeight] = useState(size);
  const [width, setWidth] = useState(size);
  const [fontSize, setFontSize] = useState(24);

  useEffect(() => {
    let size =
      (((400 - percentageValue) * percentageValue) / percentageValue) * 0.8;

    if (!Number.isFinite(size)) {
      size = 1;
    }

    setHeight((percentageValue * size) / 100);
    setWidth((percentageValue * size) / 100);
    //setFontSize((percentageValue * size) / 100 / 5);
    setSize(size);
  }, [props]);

  return (
    <Box
      className={classes.container}
      style={{ maxHeight: size, maxWidth: size }}
    >
      <svg height={height} width={width}>
        <circle
          cx={width / 2}
          cy={height / 2}
          r={(height / 5) * 2}
          fill={color}
        />
        <text
          x={width / 2}
          y={height / 2}
          textAnchor="middle"
          fill="#fff"
          fontSize={fontSize}
          dy=".3em"
        >
          {percentageValue}%
        </text>
      </svg>
    </Box>
  );
};
