import axios from 'axios';

const dev = process.env.NODE_ENV === 'development';
const baseURL = '/api';
const timeout = dev ? 30000 : 12000;

const getAuthHeader = () => ({
  headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
});

export const jsonApi = axios.create({
  baseURL,
  timeout,
  headers: { 'Content-Type': 'application/json' },
});

const formApi = axios.create({
  baseURL,
  timeout,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
});

export const get = (url: any) => {
  return jsonApi.get(url, { ...getAuthHeader() });
};

export const post = (url: any, data: any) => {
  return jsonApi.post(url, data, { ...getAuthHeader() });
};

export const formPost = (url: any, data: any) => {
  return formApi.post(url, data, { ...getAuthHeader() });
};

export const put = (url: any, data: any) => {
  return jsonApi.put(url, data, { ...getAuthHeader() });
};

export const del = (url: any) => {
  return jsonApi.delete(url, { ...getAuthHeader() });
};

export const deleteAPI = (url: any, data: any) => {
  return jsonApi.delete(url, { data: data, ...getAuthHeader() });
};
