import { SortOrderType } from '../types';

export const objToQueryString = (obj: any) =>
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&');

export const isEmptyOrIsNull = (value: any) =>
  value === '' ||
  !value ||
  value === undefined ||
  value === 'undefined' ||
  value === null ||
  value === 'null';

export const sortData = (data: any[], sortKey: string, sortBy: SortOrderType) =>
  [...data].sort(
    (a: any, b: any) =>
      (a[sortKey] < b[sortKey] ? -1 : 1) *
      (sortBy === SortOrderType.DESC ? 1 : -1)
  );

export const calcDurationFromSeconds = (toolDuration: number) => {
  const sec = toolDuration;
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  const duration = {
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };

  return duration;
};

export const calcDurationInSeconds = (
  hours: number,
  minutes: number,
  seconds: number
) => {
  let hoursInSeconds = hours * 60 * 60;
  let minutesInSeconds = minutes * 60;
  return hoursInSeconds + minutesInSeconds + seconds;
};

export const isURL = (str: any) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
};

export const convertToDate = (strDate: string): Date => {
  const [d, m, y]: number[] = strDate.split('.').map((i) => Number(i));
  const date = new Date();
  date.setDate(d);
  date.setMonth(m - 1);
  date.setFullYear(y);
  return date;
};

export const subtractMonths = (date: Date, months: number = 0): Date => {
  date.setMonth(date.getMonth() - months);
  return date;
};

export const sortDataByDateProp = (
  data: any[],
  sortKey: string,
  sortBy: SortOrderType
) =>
  [...data].sort(
    (a: any, b: any) =>
      (convertToDate(a[sortKey]) < convertToDate(b[sortKey]) ? -1 : 1) *
      (sortBy === SortOrderType.ASC ? 1 : -1)
  );
