import { Box, Fab, List, ListItem, makeStyles } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React, { FC, useEffect, useState } from 'react';
import { deleteClient, getLeadListAPI } from '../../../../api';
import { ConfirmationPopup } from '../../../../components/common';
import { IF } from '../../../../components/core';
import { FilterType, SortOrderType } from '../../../../types';
import {
  subtractMonths,
  convertToDate,
  sortDataByDateProp,
} from '../../../../utils';
import { Lead } from './';
import { ActionPanel } from './ActionPanel';

const useStyles = makeStyles((theme) => ({
  leadContainer: {
    marginTop: '50px',
  },
  actionPanelContainer: {
    padding: '16px 0 0',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #bcbcbc',
  },
  mainHeading: {
    fontFamily: 'PTSerif',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.12,
    color: '#5f5f5f',
    textAlign: 'left',
    paddingBottom: 10,
  },
  noClients: {
    fontFamily: 'Roboto',
    color: '#7b7b78',
    fontSize: 22,
    marginTop: 20,
  },
  leadList: {
    maxHeight: '60vh',
    overflowY: 'auto',
    paddingRight: 15,
    '& .MuiListItem-gutters': {
      padding: 0,
    },
  },
  listContainer: {
    position: 'relative',
  },
  floatingActionButton: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#f8f8f8',
    padding: 30,
    borderRadius: '5px',
  },
  deleteIcon: {
    padding: '2px',
    borderRadius: '50%',
    backgroundColor: '#fd5f58',
    color: '#ffffff',
    marginLeft: 15,
  },
}));

export const LeadList: FC = () => {
  const classes = useStyles();
  const [leads, setLeads] = useState<Array<any>>([]);
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortOrderType.DESC);
  const [orgLeads, setOrgLeads] = useState<any>([]);
  const [sortBy] = useState('c_date');
  const [selectedLeads, setSelectedLeads] = useState<Array<number>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getLeads();
  }, []);

  const getLeads = async () => {
    try {
      const res = await getLeadListAPI();
      const newArr = res.data.map((lead: any) => ({
        ...lead,
        checked: false,
      }));
      const sortedData = [...sortDataByDateProp(newArr, sortBy, sortOrder)];
      setLeads(sortedData);
      setOrgLeads(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLeads([...sortDataByDateProp(leads, sortBy, sortOrder)]);
  }, [sortOrder]);

  const handleChangeFilter = (filterType: FilterType) => {
    setSelectedLeads([]);
    let filteredList = [...orgLeads];
    switch (filterType) {
      case FilterType.OLDER_THAN_3_MONTHS:
        filteredList = [...orgLeads].filter(
          (i) =>
            convertToDate(i.c_date).getTime() <
            subtractMonths(new Date(), 3).getTime()
        );
        break;

      case FilterType.ALL:
      default:
        filteredList = [...orgLeads];
        setSearch('');
        break;
    }
    setLeads([...sortDataByDateProp(filteredList, sortBy, sortOrder)]);
  };

  const handleSelectedLead = (lead: any) => {
    let leads =
      lead.checked === true
        ? [...selectedLeads, lead.user_id]
        : selectedLeads.filter((i) => i !== lead.user_id);
    setSelectedLeads(leads);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    const payload = {
      ids: selectedLeads,
    };
    try {
      const res = await deleteClient(payload);
      setSelectedLeads([]);
      getLeads();
    } catch (err: any) {
      console.log(err);
    }
    setOpen(false);
  };

  const handleSearchField = (value: any) => {
    setSearch(value);
    setLeads([...filterSearchedUsers(orgLeads, value)]);
  };

  const filterSearchedUsers = (data: any[], value: string) =>
    [...data].filter((user) => user.email.includes(value));

  return (
    <Box className={classes.leadContainer}>
      <Box className={classes.actionPanelContainer}>
        <div
          className={classes.mainHeading}
          data-testid="nonregistered-clientlist-title"
        >
          Klienten, nicht registriert
        </div>
        <ActionPanel
          sortOrder={sortOrder}
          handleChangeSort={(sortType: SortOrderType) => setSortOrder(sortType)}
          handleChangeFilter={(filterType: FilterType) =>
            handleChangeFilter(filterType)
          }
          showThreeDotsMenu={true}
          onChange={(e: any) => handleSearchField(e.target.value)}
          value={search}
        />
      </Box>
      {leads.length === 0 ? (
        <div className={classes.noClients}>
          Sie haben noch keine nicht registrierte Klienten.
        </div>
      ) : (
        <Box className={classes.listContainer}>
          <List className={classes.leadList}>
            {leads.map((lead: any, index: number) => (
              <ListItem key={index}>
                <Lead
                  lead={lead}
                  selectedLeads={selectedLeads}
                  onCheckChange={handleSelectedLead}
                />
              </ListItem>
            ))}
          </List>
          {selectedLeads.length !== 0 && (
            <Fab
              onClick={handleOpen}
              className={classes.floatingActionButton}
              variant="extended"
              aria-label="clear"
              data-testid="loschen"
            >
              Löschen
              <ClearRoundedIcon
                fontSize="small"
                className={classes.deleteIcon}
              />
            </Fab>
          )}
        </Box>
      )}
      <IF condition={open}>
        <ConfirmationPopup
          title={'Bist Du sicher?'}
          description={''}
          buttonClose={'abbrechen'}
          button={'Löschen'}
          buttonClass={'buttonRed'}
          onChange={handleSubmit}
          onClose={() => setOpen(false)}
        />
      </IF>
    </Box>
  );
};

export default LeadList;
