import React, { FC, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import {
  DataPrivacy,
  Imprint,
  Login,
  PrivateRoute,
  NeuroSensitivityTest,
  RegistrationConfirmation,
  ResetPassword,
  LandingPage,
  DasWars,
  CreateAccount,
} from './views';
import { Admin } from './admin';
import { logout } from './utils/auth';
import { Dashboard } from './pages/general/';
import { CoachesClients } from './pages/coach/clients';
import { Landing, MainFooter, Mainheader } from './components/common';
import AdminPanel from './pages/admin';
import FAQ from './views/FAQ';

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
  },
  background: {
    minHeight: 'calc(100vh - 86px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 'calc(10px + 2vmin)',
    position: 'relative',
    overflow: 'hidden',
    marginTop: '40px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '12vh',
      left: '-40vw',
      zIndex: '-1',
      height: '60vw',
      width: '60vw',
      background:
        'transparent radial-gradient(closest-side at 50% 50%, #FFF873 0%, #C3C316 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
      opacity: 0.5,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-30vh',
      right: '-25vw',
      zIndex: '-1',
      height: '50vw',
      width: '50vw',
      background:
        'transparent radial-gradient(closest-side at 50% 50%, #FFF873 0%, #D31257 0%, #C3613931 51%, #FAF2EF05 74%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
      opacity: 0.5,
    },
  },
}));

const excludeHeaderRoutes = ['login', 'create-account', 'das-wars', 'test'];

export const Routes: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [showLoginButtons, setShowLoginButtons] = useState(true);

  useEffect(() => {
    return history.listen((location) => {
      setShowLoginButtons(
        !excludeHeaderRoutes.includes(location.pathname.replace(/\//gi, ''))
      );
    });
  }, [history]);

  useEffect(() => {
    setShowLoginButtons(
      !excludeHeaderRoutes.includes(
        history.location.pathname.replace(/\//gi, '')
      )
    );
  }, []);

  return (
    <Switch>
      <Route path="/admin">
        <Admin />
      </Route>

      <Route path="/coaches-test">
        <div className={classes.app}>
          <div>
            <NeuroSensitivityTest />
          </div>
        </div>
      </Route>

      <div className={classes.app}>
        <Mainheader showLoginButtons={showLoginButtons} />
        <div className={classes.background}>
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/das-wars" component={DasWars} />
          <Route path="/faqs" component={FAQ} />
          <Route path="/datenschutz" component={DataPrivacy} />
          <Route path="/impressum" component={Imprint} />
          <Route
            path="/user-registration-confirm"
            component={RegistrationConfirmation}
          />
          <Route path="/test-confirm" component={RegistrationConfirmation} />
          <Route
            path="/logout"
            render={() => {
              logout();
              history.push('/');
              return null;
            }}
          />
          <Route path="/test">
            <NeuroSensitivityTest landing={<Landing />} showText={true} />
          </Route>
          <Route exact path="/" component={LandingPage} />
          <PrivateRoute path="/client/dashboard" component={Dashboard} />
          <Switch>
            <PrivateRoute
              path="/coach/clients/dashboard"
              component={Dashboard}
            />
            <PrivateRoute path="/coach/clients" component={CoachesClients} />
            <PrivateRoute path="/admin-page" component={AdminPanel} />
          </Switch>
          <MainFooter />
        </div>
      </div>
    </Switch>
  );
};
