import { EditableTextArea, IF } from '../core';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, Fragment, useState, useEffect } from 'react';
import { NextStepsType } from '../../types';
import { isCoach } from '../../utils';
import { ToolsListPopup } from '../../pages/coach/clients/components';
import { SelectedTool } from './';
import {
  getAllToolsAPI,
  getNextStepsDataAPI,
  handleNextStepsSubmissionAPI,
  getSelectedToolsAPI,
} from '../../api';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '20px',
    textAlign: 'left',
  },
  titleContainer: {},
  title: {
    fontWeight: 'bold',
    lineHeight: '0.88',
    color: theme.palette.common.white,
    marginBottom: '20px',
    textTransform: 'uppercase',
  },
  hr: {
    border: '1px solid #9d9d9d',
    margin: 0,
  },
  textAreaContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    backgroundColor: '#9d9d9d',
    borderRadius: '10px',
    margin: '20px 0',
    padding: '16px',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
  },
  saveBtn: {
    color: theme.palette.common.white,
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#7b7b78',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  iconArrowForward: {
    fontSize: 18,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
    marginLeft: '5px',
    padding: '5px',
  },
  toolsLinksContainer: {
    display: 'flex',
    gap: '16px',
    marginTop: '20px',
    borderRadius: '10px',
    backgroundColor: '#9d9d9d',
    padding: '40px 20px',
    flexWrap: 'wrap',
  },
  disabled: {
    opacity: 0.7,
  },
}));

type Props = {
  moduleDone: boolean;
  description: string;
  showEditButtons: boolean;
  email: string;
  moduleId: number;
  submoduleId: number;
  nextModuleId: number;
  onChangeToolSelection: Function;
  moduleNumber: number;
};

export const WeiteresVorgehen: FC<Props> = ({
  description,
  showEditButtons,
  moduleDone,
  email,
  moduleId,
  submoduleId,
  nextModuleId,
  onChangeToolSelection,
  moduleNumber,
}) => {
  const classes = useStyles();

  const [nextStepsDesc, setNextStepsDesc] = useState<string>(description);
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const [selectedToolsList, setSelectedToolsList] = useState<Array<number>>([]);

  const [nextStepsData, setNextStepsData] = useState<NextStepsType | null>(
    null
  );

  const [allToolsList, setAllToolsList] = useState([{}]);

  useEffect(() => {
    getNextStepsData();
    getToolsFromAPI();
    if (moduleDone) getSelectedTools();
  }, []);

  useEffect(() => {
    onChangeToolSelection(selectedToolsList);
  }, [selectedToolsList]);

  const handleChangeToolSelection = (toolIds: Array<number>) => {
    setSelectedToolsList(toolIds);
  };

  const removeToolFromList = (toolId: number) => {
    setSelectedToolsList((list) => {
      let newList = [...list];
      let toolToBeRemoved = list.indexOf(toolId);
      newList.splice(toolToBeRemoved, 1);
      return newList;
    });
  };

  const getNextStepsData = async () => {
    const payload = {
      email: email,
      course: 1,
      submodule: submoduleId,
      module: moduleId,
    };

    try {
      const res = await getNextStepsDataAPI(payload);
      setNextStepsData(res.data);
      setNextStepsDesc(res.data.next_steps);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getSelectedTools = async () => {
    let payload = {
      email: email,
      course: 1,
      module: nextModuleId,
    };

    try {
      const res = await getSelectedToolsAPI(payload);
      let selectedTools: number[] = [];
      res.data.map((tool: any) => {
        selectedTools.push(tool.tool_id);
      });
      setSelectedToolsList(selectedTools);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getToolsFromAPI = async () => {
    try {
      const res = await getAllToolsAPI();
      setAllToolsList(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleNextStepsSubmission = async (nexSteps: string) => {
    setReadOnly(!readOnly);
    const payload = {
      email: email,
      course: 1,
      submodule: submoduleId,
      module: moduleId,
      next_steps: nexSteps,
    };
    try {
      const res = await handleNextStepsSubmissionAPI(payload);
      setNextStepsDesc(res.data.next_steps);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Box className={classes.wrapper}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            {'Weiteres Vorgehen, Anmerkungen, Notizen'}
          </Typography>
        </Box>
        <hr className={classes.hr} />
        <Box className={classes.textAreaContainer}>
          <EditableTextArea
            data-testid="editable-textarea-coaching"
            readOnly={readOnly}
            textContent={nextStepsDesc}
            onChange={(e) => setNextStepsDesc(e.target.value)}
          />

          <Box>
            {showEditButtons && !moduleDone ? (
              <Box className={classes.btnContainer}>
                {readOnly === true ? (
                  <Button
                    data-testid="bearbeiten-coaching"
                    onClick={() => setReadOnly(!readOnly)}
                    className={classes.saveBtn}
                  >
                    <Typography>bearbeiten</Typography>
                    <EditIcon className={classes.iconArrowForward} />
                  </Button>
                ) : (
                  <Button
                    data-testid="speichern-coaching"
                    onClick={() => handleNextStepsSubmission(nextStepsDesc)}
                    className={classes.saveBtn}
                  >
                    <Typography>speichern</Typography>
                    <CloudUploadIcon className={classes.iconArrowForward} />
                  </Button>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>

        <IF condition={nextModuleId !== -1 || moduleNumber === 1}>
          <Box className={classes.titleContainer}>
            <Typography className={classes.title}>{'TOOLS'}</Typography>
          </Box>
          <hr className={classes.hr} />
          <Box
            className={`${classes.toolsLinksContainer} ${
              moduleDone ? classes.disabled : null
            }`}
          >
            {selectedToolsList.map((el: number, index: number) => (
              <SelectedTool
                key={index}
                moduleDone={moduleDone}
                toolId={el}
                isCoach={isCoach()}
                onRemoveTool={removeToolFromList}
              />
            ))}
            <IF condition={isCoach()}>
              <ToolsListPopup
                moduleDone={moduleDone}
                allToolsList={allToolsList}
                onChange={handleChangeToolSelection}
                selectedToolsData={selectedToolsList}
              />
            </IF>
          </Box>
        </IF>
      </Box>
    </Fragment>
  );
};
