import React, { FC, useEffect, useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { confirmRegistration, confirmTest } from '../api/';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'rgba(14,165,84,0.5)',
    padding: theme.spacing(10),
  },
  mainText: {
    color: '#ffffff',
    fontFamily: "'Noto Sans', sans-serif",
    opacity: 1,
    fontSize: 24,
    fontWeight: 600,
  },
  btnContainer: {
    width: 350,
    paddingTop: theme.spacing(4),
    margin: 'auto',
  },
  btn: {
    background: '#5f5f5f',
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 2,
    '&:hover': {
      background: '#3e3e3e',
      color: 'white',
      boxShadow: 'none',
    },
  },
}));

export const RegistrationConfirmation: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const path = useLocation().pathname;
  let query = useQuery();
  const [qParam] = useState(query.get('token'));

  useEffect(() => {
    qParam !== null && sendConfirmationAPI(qParam);
  }, []);

  const sendConfirmationAPI = async (token: string) => {
    if (path == '/user-registration-confirm') {
      try {
        const res = await confirmRegistration(token);
      } catch (err: any) {
        console.log(err);
      }
    } else if (path == '/test-confirm') {
      try {
        const res = await confirmTest(token);
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const directOnClick = () => {
    if (path == '/user-registration-confirm') {
      history.push('/login');
    } else if (path == '/test-confirm') {
      history.push('/');
    }
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.mainText} data-testid="main-text">
          Die Mail-Adresse wurde bestätigt.
        </Box>
        <Box className={classes.btnContainer}>
          <Button
            data-testid="alles-klar"
            variant="contained"
            className={classes.btn}
            size="large"
            fullWidth
            onClick={directOnClick}
          >
            ALLES KLAR
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
