import { Box, makeStyles } from '@material-ui/core';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  clientDashboardData,
  getCourseStructureAPI,
  getModuleDashboard,
  handleChangeModuleSubmission,
  updateSensitivityStateDashboardData,
  getClientProfileAPI,
} from '../../api';
import {
  CoachingSession,
  PraxisModul,
  Sensitivity,
} from '../../components/shared';
import {
  ClientDashboardType,
  EnergylevelType,
  QuestionsAndAnswersType,
  SensitivityTypescaleType,
  StatusMeinerDataType,
} from '../../types';
import { isClient, isCoach } from '../../utils';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    margin: '0px 10% 20px',
  },
  module: {
    marginTop: 20,
  },
}));

export const Dashboard: FC = () => {
  const [clientData, setClientData] = useState<ClientDashboardType | null>(
    null
  );

  const [coordinates, setCoordinates] = useState<any>([]);

  const [activeModule, setActiveModule] = useState<any>({});

  const [circlesData, setCirclesData] = useState<StatusMeinerDataType | null>(
    null
  );

  const [energylevelData, setEnergylevelData] =
    useState<EnergylevelType | null>(null);

  const [sensitivityTypescaleData, setSensitivityTypescaleData] =
    useState<SensitivityTypescaleType | null>(null);

  const [questionsAndAnswersData, setQuestionsAndAnswersData] =
    useState<QuestionsAndAnswersType | null>(null);

  const [email, setEmail] = useState('');
  const [sensitivityApiErrorMsg, setSensitivityApiErrorMsg] =
    useState<string>('');
  const clientEmail = localStorage.getItem('email') || '';

  const [moduleArray, setModuleArray] = useState<Array<any>>([]);
  const [editModulePosition, setEditModulePosition] = useState<number>();
  const [clientProfile, setClientProfile] = useState<any>({
    email: '',
    first_name: '',
    last_name: '',
    nick_name: '',
    image: '',
    tel_number: '',
  });
  const [focus, setFocus] = useState<Array<any>>([]);

  const location = useLocation();
  let emailProp = '';
  location.state
    ? (emailProp = (location.state as any).emailProp)
    : (emailProp = '');

  useEffect(() => {
    const email = isCoach() ? emailProp : clientEmail;
    setEmail(email);
    getDashboardData(email);
    getCourseStructure(email);
    if (isCoach()) {
      getClientProfile(email);
    }
  }, []);

  const classes = useStyles();

  const getDashboardData = async (email: any) => {
    const payload = {
      email: email,
    };
    try {
      const res = await clientDashboardData(payload);
      handleClientDashboardResponse(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleClientDashboardResponse = (data: any) => {
    setClientData(data);
    setCoordinates([[...data.coordinates]]);
    setCirclesData({
      efp: data.efp,
      wahrnehmungsfaehigkeit: data.wahrnehmungsfaehigkeit,
      sonnenseite: data.sonnenseite,
      efn: data.efn,
      unvertrauen: data.unvertrauen,
      schattenseite: data.schattenseite,
    });
    setEnergylevelData({
      energie: data.energie,
    });
    setSensitivityTypescaleData({
      neurotype: data.neurotype,
      auspraegung: data.auspraegung,
    });
    setQuestionsAndAnswersData({
      a_eight: data.a_eight,
      a_five: data.a_five,
      a_four: data.a_four,
      a_one: data.a_one,
      a_seven: data.a_seven,
      a_six: data.a_six,
      a_three: data.a_three,
      a_two: data.a_two,
    });
    setFocus(data.fokus);
  };

  const handleChangeSubmission = async (moduleId: number) => {
    const payload = {
      email: email,
      module_id: moduleId,
    };

    const sensitivityPayload = {
      module_id: activeModule?.id,
      email: email,
      answer1: questionsAndAnswersData?.a_one,
      answer2: questionsAndAnswersData?.a_two,
      answer3: questionsAndAnswersData?.a_three,
      answer4: questionsAndAnswersData?.a_four,
      answer5: questionsAndAnswersData?.a_five,
      answer6: questionsAndAnswersData?.a_six,
      answer7: questionsAndAnswersData?.a_seven,
      answer8: questionsAndAnswersData?.a_eight,
      fokus: focus,
    };

    try {
      getDashboardData(email);
      onUpdateSensitivityState(sensitivityPayload);
      const res = await handleChangeModuleSubmission(payload);
      getCourseStructure(email);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getCourseStructure = async (email: any) => {
    const payload = {
      email,
    };
    try {
      const res = await getCourseStructureAPI(payload);
      setModuleArray(sortedModulesArray(res.data));
    } catch (err: any) {
      console.log(err);
    }
  };

  const sortedModulesArray = (modules: any) => {
    modules.sort((a: any, b: any) => {
      return a.position - b.position;
    });
    setActiveModule(modules.find((i: any) => i.module_done === false));
    return modules;
  };

  const getNextModuleId = (index: number) => {
    let nextModule = moduleArray[index + 1];
    let nextModuleId: number;
    index < moduleArray.length - 1
      ? (nextModuleId = nextModule?.id)
      : (nextModuleId = -1);

    return nextModuleId;
  };

  const getPrevModuleId = (index: number) => {
    let prevModule = moduleArray[index - 1];
    let prevModuleId: number;
    index < moduleArray.length && index > -1
      ? (prevModuleId = prevModule?.id)
      : (prevModuleId = -1);

    return prevModuleId;
  };

  const getPrevModuleDone = (index: number) => {
    let prevModule = moduleArray[index - 1];
    let prevModuleDone: boolean;
    index < moduleArray.length && index > -1
      ? (prevModuleDone = prevModule?.module_done)
      : (prevModuleDone = false);

    return prevModuleDone;
  };

  const onUpdateSensitivityState = async (payload: any) => {
    try {
      const res = await updateSensitivityStateDashboardData({
        ...payload,
        email,
        module_id: activeModule?.id,
      });
      handleClientDashboardResponse(res.data);
    } catch (err: any) {
      console.log(err);
      setSensitivityApiErrorMsg(err.response.data.detail);
    }
  };

  const editDashboard = async (id: number, position: number) => {
    setEditModulePosition(position);
    const payload = {
      module_id: position % 2 === 0 ? id - 1 : id,
      email: email,
    };
    try {
      const res = await getModuleDashboard(payload);
      handleClientDashboardResponse(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getClientProfile = async (email: any) => {
    const payload = {
      email: email,
    };
    try {
      const res = await getClientProfileAPI(payload);
      setClientProfile(res.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Box className={classes.accordionContainer}>
        <Sensitivity
          coordinates={coordinates}
          activeModule={activeModule?.position}
          sensitivityTypescaleData={sensitivityTypescaleData}
          energylevelData={energylevelData}
          circlesData={circlesData}
          questionsAndAnswersData={questionsAndAnswersData}
          isCoach={isCoach()}
          onUpdateSensitivityState={onUpdateSensitivityState}
          apiErrorMsg={sensitivityApiErrorMsg}
          fokus={clientData?.fokus || null}
          editDashboard={editDashboard}
          modules={moduleArray}
          editModulePosition={editModulePosition}
          email={clientProfile.email}
          firstName={clientProfile.first_name}
          lastName={clientProfile.last_name}
          image={clientProfile.image}
          nickname={clientProfile.nick_name}
          telephone={clientProfile.telefon}
        />

        {moduleArray.map((module: any, index: number) => (
          <Box key={index} className={classes.module}>
            {module.type ? (
              <CoachingSession
                moduleName={module.name}
                moduleNumber={module.position}
                moduleDone={module.module_done}
                moduleId={module.id}
                submoduleId={module.submodule_id[0]}
                nextModuleId={getNextModuleId(index)}
                prevModuleId={getPrevModuleId(index)}
                prevModuleDone={getPrevModuleDone(index)}
                email={email}
                isCoach={isCoach()}
                showEditButtonsWeiteresVorgehen={isCoach()}
                handleChangeSubmission={handleChangeSubmission}
                activeModule={activeModule?.position}
              />
            ) : (
              <PraxisModul
                moduleName={module.name}
                moduleNumber={module.position}
                moduleDone={module.module_done}
                moduleId={module.id}
                numofsubmodules={module.numofsubmodules}
                email={email}
                isClient={isClient()}
                handleChangeSubmission={handleChangeSubmission}
                prevModuleDone={getPrevModuleDone(index)}
                activeModule={activeModule?.position}
              />
            )}
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};
