import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Box,
  TextField,
  DialogTitle,
} from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
//import defaultProfilePic from '../../assets/defaultProfilePic.svg';
import defaultProfilePic from '../../assets/dummyProfilePics/dudsy14.png';
import CloseIcon from '@material-ui/icons/Add';
import {
  getClientProfileDetailsAPI,
  updateClientProfileDetailsAPI,
  updateClientProfilePictureAPI,
} from '../../api';
import { ClientType } from '../../types';
import { ChangePassword, UploadImage } from '.';
import { IF } from '../core';
import { objToQueryString } from '../../utils';

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-root': {
      minWidth: '60vw',
      backgroundColor: '#f7f6f1',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: '0 20px 20px',
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-root': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
      },
      '& .MuiDialogContent-root > p': {
        color: 'white',
        fontStyle: 'italic',
        marginBottom: 0,
      },
      '& .MuiDialogActions-root': {
        justifyContent: 'center',
      },
      '& .MuiDialogActions-root .MuiButtonBase-root': {
        color: 'white',
      },
    },
  },
  imageIcon: {
    cursor: 'pointer',
    width: 25,
  },
  profileImage: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    borderRadius: '50%',
    '& > img': {
      borderRadius: '50%',
      width: 25,
      height: 25,
      objectFit: 'cover',
    },
  },
  editProfileImage: {
    cursor: 'unset',
    margin: 'auto',
    width: 54,
    height: 54,
    position: 'relative',
    '& > img': {
      position: 'relative',
      borderRadius: '50%',
      width: 54,
      height: 54,
      objectFit: 'cover',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 65,
      height: 65,
      backgroundColor: 'white',
      borderRadius: '50%',
      zIndex: 0,
    },
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.17,
    letterSpacing: 0.72,
    color: '#5f5f5f',
    textAlign: 'center',
    padding: 10,
    borderBottom: '1px solid #dbdee7',
    marginBottom: 20,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    position: 'relative',
    width: '100%',
    margin: '10px 0 20px 0',
  },
  closeIcon: {
    color: '#8e8e8e',
    transform: 'translateY(-50%) rotate(45deg)',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
  },
  textFieldContainer: {
    marginTop: 20,
  },
  textFieldRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      margin: '0 10px',
    },
    '& .MuiBox-root, & .MuiBox-root > .MuiFormControl-root': {
      width: '100%',
    },
  },
  multilineFieldContainer: {
    margin: '0 10px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-input, & .MuiOutlinedInput-multiline': {
      backgroundColor: 'white',
    },
  },
  multilineLabel: {
    textAlign: 'left',
    color: '#5f5f5f',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginBottom: 20,
    width: 330,
    alignSelf: 'center',
  },
  outlineButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #aaa9a8',
    color: '#8e8e8e',
    padding: '7px 70px',
    width: 330,
    '& > span': {
      color: '#24c776',
      fontWeight: 'bold',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  closeButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: '1px solid #8e8e8e',
    color: '#8e8e8e',
    padding: '7px 30px',
    width: 160,
    '& > span': {
      color: '#5f5f5f',
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(239,239,239,0.7)',
    },
  },
  saveButton: {
    backgroundColor: '#24c776',
    padding: '7px 30px',
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    width: 160,
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
}));

const initialData = {
  activated: true,
  email: '',
  first_name: '',
  last_name: '',
  neurotype: '',
  nickname: '',
  image: '',
  telefon: '',
};

export const EditClientProfile: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [upload, setUpload] = useState(false);
  const [newImage, setNewImage] = useState(defaultProfilePic);
  const [image, setImage] = useState(defaultProfilePic);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [nickname, setNickname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [imageFile, setImageFile] = useState<FormData>();

  const [client, setClient] = useState<ClientType>(initialData);
  const [changePW, setChangePW] = useState(false);

  useEffect(() => {
    getProfileDetails();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    getProfileDetails();
  };

  const getProfileDetails = async () => {
    try {
      const res = await getClientProfileDetailsAPI();
      setClientDetails(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const setClientDetails = (client: ClientType) => {
    setClient(client);
    setName(client.first_name);
    setSurname(client.last_name);
    setNickname(client.nickname);
    setPhoneNumber(client.telefon);

    client.image ? setNewImage(client.image) : setNewImage(defaultProfilePic);
    client.image ? setImage(client.image) : setImage(defaultProfilePic);
  };

  const handleClose = () => {
    setOpen(false);
    setChangePW(false);
  };

  const handleUploadImage = () => {
    setUpload(true);
  };

  const handleSave = () => {
    setOpen(false);
    updateProfileDetails();

    if (image !== newImage) {
      setImage(newImage);
      updateProfilePicture();
    }
  };

  const updateProfileDetails = async () => {
    const payload = {
      first_name: name,
      last_name: surname,
      nickname: nickname,
      telefon: phoneNumber,
    };
    try {
      const res = await updateClientProfileDetailsAPI(payload);
    } catch (err) {
      console.log(err);
    }
  };

  const updateProfilePicture = async () => {
    const qs = objToQueryString({ email: localStorage.getItem('email') });
    try {
      const res = await updateClientProfilePictureAPI(qs, imageFile);
    } catch (err) {
      console.log(err);
    }
  };

  const handleName = (event: any) => {
    if (event.target.value !== '') setName(event.target.value);
  };

  const handleSurname = (event: any) => {
    if (event.target.value !== '') setSurname(event.target.value);
  };

  const handleNickname = (event: any) => {
    if (event.target.value !== '') setNickname(event.target.value);
  };

  const handlePhoneNumber = (event: any) => {
    setPhoneNumber(event.target.value);
  };

  const updateUpload = (image: string, file: FormData) => {
    setNewImage(image);
    setImageFile(file);
  };

  return (
    <Box>
      <Box
        className={classes.profileImage}
        onClick={handleClickOpen}
        data-testid="client-profile"
      >
        <img src={image} />
      </Box>
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box className={classes.heading}>
            <Box data-testid="client-profile-title">{'Mein Profil'}</Box>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
              data-testid="close-icon"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <IF condition={!upload}>
            <Box
              className={`${classes.profileImage} ${classes.editProfileImage}`}
              // onClick={handleUploadImage}
              data-testid="display-picture"
            >
              <img src={newImage} />
            </Box>
          </IF>
          <IF condition={upload}>
            <UploadImage
              onCloseUpload={() => setUpload(false)}
              onSaveUpload={(image: any, file: any) =>
                updateUpload(image, file)
              }
            />
          </IF>
          <Box className={classes.textFieldContainer}>
            <Box className={classes.textFieldRow}>
              <Box mb={4}>
                <TextField
                  id={'name'}
                  variant="outlined"
                  placeholder={'Vorname'}
                  onChange={handleName}
                  defaultValue={name}
                  inputProps={{ 'data-testid': 'firstname' }}
                />
              </Box>
              <Box mb={4}>
                <TextField
                  id={'surname'}
                  variant="outlined"
                  placeholder={'Name'}
                  onChange={handleSurname}
                  defaultValue={surname}
                  inputProps={{ 'data-testid': 'lastname' }}
                />
              </Box>
            </Box>
            <Box className={classes.textFieldRow}>
              <Box mb={4}>
                <TextField
                  id={'nickname'}
                  variant="outlined"
                  placeholder={'Benutzername'}
                  onChange={handleNickname}
                  defaultValue={nickname}
                  inputProps={{ 'data-testid': 'nickname' }}
                />
              </Box>
              <Box mb={4}>
                <TextField
                  id={'phone'}
                  variant="outlined"
                  placeholder={'Telefonnummer'}
                  onChange={handlePhoneNumber}
                  defaultValue={phoneNumber}
                  inputProps={{ 'data-testid': 'telephone' }}
                />
              </Box>
            </Box>
          </Box>
          <IF condition={!changePW}>
            <Box mb={2}>
              <Button
                className={classes.outlineButton}
                onClick={() => setChangePW(true)}
                data-testid="change-client-password"
              >
                {'Passwort ändern'}
              </Button>
            </Box>
          </IF>
          <IF condition={changePW}>
            <ChangePassword onChange={() => setChangePW(false)} />
          </IF>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button
            className={classes.closeButton}
            onClick={handleClose}
            data-testid="cancel-client-update"
          >
            {'abbrechen'}
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleSave}
            data-testid="confirm-client-update"
          >
            {'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditClientProfile;
