import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Box,
  DialogTitle,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone';
import { ConfirmationPopup } from '../../../components/common';
import { CSVUploadAPI } from '../../../api';

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    fontFamily: 'PTSerif',
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.12,
    color: '#5f5f5f',
    textAlign: 'left',
    paddingBottom: 10,
  },
  popupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #bcbcbc',
  },
  popup: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
      backgroundColor: '#f7f6f1',
      borderRadius: 5,
      color: 'white',
      textAlign: 'center',
      padding: '0 20px 20px',
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
    },
    '& .MuiDialog-container .MuiPaper-root': {
      backgroundColor: '#f7f6f1',
      maxWidth: 'unset',
      color: '#f8f8f8',
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  addIcon: {
    background: '#24c776',
    borderRadius: '50%',
    color: '#f8f8f8',
    padding: 5,
    cursor: 'pointer',
  },
  heading: {
    position: 'relative',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.17,
    letterSpacing: 0.72,
    color: '#5f5f5f',
    textAlign: 'center',
    padding: 10,
    borderBottom: '1px solid #dbdee7',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    margin: '10px 0 20px 0',
  },
  closeIcon: {
    color: '#8e8e8e',
    transform: 'translateY(-50%) rotate(45deg)',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '50%',
  },
  uploadContainer: {},
  upload: {
    backgroundColor: 'white',
    padding: 120,
    textAlign: 'center',
    borderRadius: 5,
  },
  cloudIcon: {
    width: 200,
    height: 200,
  },
  uploadText: {
    color: '#96a0b0',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  boldText: {
    color: '#3e3e3e',
    cursor: 'pointer',
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: '1px solid #8e8e8e',
    color: '#8e8e8e',
    textTransform: 'none',
    padding: '7px 30px',
    width: 160,
  },
  saveButton: {
    backgroundColor: '#24c776',
    padding: '7px 30px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'white',
    width: 160,
    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(36, 199, 118, 0.7)',
    },
  },
  noDraggedFiles: {
    border: '1px solid white',
    borderRadius: 5,
  },
  dragAcceptedFile: {
    border: '1px solid #24c776',
    borderRadius: 5,
    '& .MuiSvgIcon-root': {
      color: '#24c776',
    },
  },
  dragRejectedFile: {
    border: '1px solid #fd5f58',
    borderRadius: 5,
    '& .MuiSvgIcon-root': {
      color: '#fd5f58',
    },
  },
}));

type Props = {
  handleUpload: Function;
};

export const ToolUploadPopup: FC<Props> = ({ handleUpload }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<FormData>();
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [fileNames, setFileNames] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmationStep(false);
    setFileNames([]);
  };

  const handleSave = () => {
    CSVUpload();
    setConfirmationStep(true);
  };

  const CSVUpload = async () => {
    try {
      const res = await CSVUploadAPI(file);
      handleUpload();
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleDrop = (acceptedFiles: any) => {
    setFileNames(acceptedFiles.map((file: any) => file.name));

    let data_file = acceptedFiles?.[0];
    const formData = new FormData();
    formData.append('data_file', data_file);
    setFile(formData);
  };

  return (
    <div className={classes.popupContainer}>
      <Box className={classes.mainHeading}>Tools</Box>
      <AddIcon className={classes.addIcon} onClick={handleClickOpen} />
      <Dialog
        className={classes.popup}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box className={classes.heading}>
            <Box>CSV Upload</Box>
            <AddIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.uploadContainer}>
            <Dropzone accept="text/csv" onDrop={handleDrop}>
              {({
                getRootProps,
                getInputProps,
                isDragAccept,
                isDragReject,
              }) => (
                <section
                  className={
                    isDragAccept || (fileNames.length !== 0 && !isDragReject)
                      ? classes.dragAcceptedFile
                      : isDragReject
                      ? classes.dragRejectedFile
                      : classes.noDraggedFiles
                  }
                >
                  <div {...getRootProps()} className={classes.upload}>
                    <CloudUploadIcon className={classes.cloudIcon} />
                    <input {...getInputProps()} />

                    {isDragReject ? (
                      <p className={classes.uploadText}>
                        Dateityp wird nicht erkannt, bitte versuchen Sie es
                        erneut
                      </p>
                    ) : fileNames.length !== 0 ? (
                      <div className={classes.uploadText}>
                        {fileNames.map((fileName, index) => (
                          <p key={index} className={classes.uploadText}>
                            Datei <span key={fileName}>{fileName}</span> ist
                            bereit für Upload
                          </p>
                        ))}
                      </div>
                    ) : (
                      <p className={classes.uploadText}>
                        Datei hierhin ziehen oder{' '}
                        <span className={classes.boldText}>browsen</span> für
                        Upload
                      </p>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </Box>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Button className={classes.closeButton} onClick={handleClose}>
            {'abbrechen'}
          </Button>
          <Button
            className={classes.saveButton}
            onClick={handleSave}
            disabled={fileNames.length === 0}
          >
            {'Ok'}
          </Button>
          {confirmationStep ? (
            <ConfirmationPopup
              title={'Upload erfolgreich!'}
              description={fileNames[0]}
              buttonClose={''}
              button={'Alles klar'}
              buttonClass={'buttonGreen'}
              onChange={handleClose}
            />
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToolUploadPopup;
