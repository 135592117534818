import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '../../assets/expandWhite.svg';
import React, { FC, useState } from 'react';
import { Task } from '../common';
import toolIcon from '../../assets/toolGreen.svg';
import { submitSubmoduleAPI } from '../../api';

const useStyles = makeStyles((theme) => ({
  practicalModuleContainer: {
    fontSize: '1rem',
    background: '#7b7b78',
    padding: '10px',
    width: '100%',
  },
  practicalModuleHeading: {
    display: 'flex',
    background: '#7b7b78',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'left',
    borderBottom: '1px solid rgb(255 255 255 / 25%)',
    '& .MuiAccordionSummary-content > .MuiBox-root': {
      display: 'flex',
    },
  },
  practicalModuleAccordion: {
    width: '100%',
    boxShadow: 'none',
    color: theme.palette.common.white,
  },
  practicalModuleInner: {
    padding: '40px 20px',
    marginTop: 20,
    borderRadius: 10,
    backgroundColor: '#9d9d9d',
  },
  submoduleAccordion: {
    padding: 0,
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  submoduleAccordionSummary: {
    '& .MuiAccordionSummary-content': {
      fontSize: '1rem',
    },
  },
  submoduleHeading: {
    marginLeft: 10,
    alignSelf: 'center',
  },
  submoduleExpand: {
    color: 'white',
    width: 25,
    transform: 'rotate(180deg)',
  },
  moduleFinished: {
    color: '#24c776 !important',
  },
  moduleFinishedBg: {
    backgroundColor: '#24c776 !important',
  },
  toolIcon: {
    height: 30,
    width: 30,
  },
}));

type Props = {
  moduleId: number;
  submoduleId: number;
  submoduleName: string;
  toolId: number;
  email: string;
  isClient: boolean;
  submoduleDone: boolean;
  handleChangeSubmission: Function;
  lastSubmodule: boolean;
  taskProgress: Array<boolean>;
};

export const ToolSubmodule: FC<Props> = ({
  moduleId,
  submoduleId,
  submoduleName,
  toolId,
  email,
  isClient,
  submoduleDone,
  handleChangeSubmission,
  lastSubmodule,
  taskProgress,
}) => {
  const classes = useStyles();

  const [submoduleFinished, setSubmoduleFinished] = useState(submoduleDone);

  const handleSubmit = (submoduleId: number) => {
    submitSubmodule(submoduleId);
  };

  const submitSubmodule = async (submoduleId: number) => {
    let payload = {
      email: email,
      course: 1,
      module: moduleId,
      values: {},
      submodule: submoduleId,
    };
    try {
      const res = await submitSubmoduleAPI(payload);
      setSubmoduleFinished(true);
      handleChangeSubmission(submoduleId);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <Accordion
      defaultExpanded
      className={classes.submoduleAccordion}
      square={true}
    >
      <AccordionSummary
        expandIcon={
          <img
            className={classes.submoduleExpand}
            src={ExpandMoreIcon}
            alt="expand-icon"
          />
        }
        className={`${classes.submoduleAccordionSummary} ${classes.practicalModuleHeading}`}
        aria-controls="status"
        id="status"
      >
        <Box>
          <img className={classes.toolIcon} src={toolIcon} alt="tool-icon" />
          <Box
            className={`${classes.submoduleHeading} ${
              submoduleFinished ? classes.moduleFinished : ''
            }`}
            data-testid="submodule-name"
          >
            {submoduleName}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={`${classes.submoduleAccordion}`}>
        <Box className={classes.practicalModuleContainer}>
          <Box className={classes.practicalModuleAccordion}>
            <Box className={classes.practicalModuleInner}>
              <Task
                email={email}
                onChange={() => handleSubmit(submoduleId)}
                isClient={isClient}
                submoduleDone={submoduleFinished}
                moduleId={moduleId}
                toolId={toolId}
                submoduleId={submoduleId}
                lastSubmodule={lastSubmodule}
                taskProgress={taskProgress}
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
