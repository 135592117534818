import { Box, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { VisualizationType } from '../../types';
import { chartColors } from '../../utils';
import { ScatterChart } from '../core';

const useStyles = makeStyles((theme) => ({
  visualizationContainer: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'center',
  },
  scatterChartContainer: {},
  chartTopCaption: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  chartBottomCaption: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  firstCaption: {
    color: chartColors.first,
  },
  secondCaption: {
    color: chartColors.second,
  },
  thirdCaption: {
    color: chartColors.third,
  },
  fourthCaption: {
    color: chartColors.fourth,
  },
  moduleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '16px',
  },
  moduleBox: {
    fontSize: '16px',
    color: '#fff',
    fontWeight: 500,
  },
  moduleNumbers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
  },
  even: {
    // padding: '6px 9px 5px 9px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#fff',
    backgroundColor: '#bcbcbc',
    borderRadius: '2px',
    width: '26px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  odd: {
    // padding: '6px 10px 5px 9px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#fff',
    backgroundColor: '#bcbcbc',
    borderRadius: '50%',
    width: '26px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeModule: {
    backgroundColor: '#65c878',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  pointerNone: {
    pointerEvents: 'none',
  },
}));

type Props = {
  visualizationNames: VisualizationType;
  coordinates?: number[] | null;
  activeModule?: number;
  editDashboard: Function;
  modules: Array<any>;
  editModulePosition?: number;
};

export const Visualization: FC<Props> = ({
  visualizationNames,
  coordinates,
  activeModule,
  editDashboard,
  modules,
  editModulePosition,
}) => {
  const classes = useStyles();

  const handleClick = (id: number, position: number) => {
    editDashboard(id, position);
  };

  return (
    <Box className={classes.visualizationContainer}>
      <Box className={classes.scatterChartContainer}>
        <Box className={classes.chartTopCaption}>
          <Typography className={classes.firstCaption}>
            {visualizationNames.first}
          </Typography>
          <Typography className={classes.secondCaption}>
            {visualizationNames.second}
          </Typography>
        </Box>
        <ScatterChart
          colors={chartColors}
          coordinates={coordinates}
          moduleLabel={
            editModulePosition
              ? editModulePosition
              : activeModule
              ? activeModule
              : modules.length
          }
        />
        <Box className={classes.chartBottomCaption}>
          <Typography className={classes.thirdCaption}>
            {visualizationNames.third}
          </Typography>
          <Typography className={classes.fourthCaption}>
            {visualizationNames.fourth}
          </Typography>
        </Box>
        <Box className={classes.moduleContainer}>
          <Box className={classes.moduleBox}>Modul</Box>
          <Box className={classes.moduleNumbers}>
            {modules.map((module) => (
              <Box
                key={`modul-${module.position}`}
                data-testid={`modul-${module.position}`}
                onClick={() => handleClick(module.id, module.position)}
                className={`${
                  module.position % 2 === 0 ? classes.even : classes.odd
                } ${module.module_done === true ? classes.activeModule : ''}
                  ${
                    activeModule
                      ? activeModule > module.position - 1
                        ? classes.pointerCursor
                        : classes.pointerNone
                      : classes.pointerCursor
                  }`}
              >
                {module.position}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
