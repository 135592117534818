import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { EditableTextArea, IF, ReadonlyTitleDescription } from '../core';
import { ReflectionsType, ToolType } from '../../types';
import { SubmissionConfirmation } from '../../pages/client/dashboard/components';
import {
  getReflectionsAPI,
  getToolDataAPI,
  saveTaskProgressAPI,
  submitReflectionsAPI,
} from '../../api';
import { isURL } from '../../utils';

const useStyles = makeStyles((theme) => ({
  taskContainer: {
    color: 'white',
    fontSize: 18,
    '& > .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  heading: {
    fontWeight: 'bold',
    borderBottom: '1px solid #5f5f5f',
    textAlign: 'left',
    paddingBottom: 5,
  },
  description: {
    marginTop: '10px',
    fontStyle: 'italic',
    textAlign: 'left',
    fontSize: 14,
  },
  checkBoxContainer: {
    backgroundColor: '#5f5f5f',
    height: 'calc(100% - 90px)',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  checkBoxFormGroup: {
    backgroundColor: '#5f5f5f',
    borderRadius: 5,
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    padding: '20px',
    gap: '10px',
    '& label': {
      flex: '0 1 0px',
    },
    '& .MuiButtonBase-root': {
      width: 15,
      height: 15,
      marginTop: 10,
    },
  },
  checkBoxEnabled: {
    '& .MuiButtonBase-root.Mui-checked .MuiIconButton-label': {
      backgroundColor: 'white',
      width: 15,
      height: 15,
    },
    '& .checkBoxLabel': {
      color: 'white',
    },
    '& .MuiCheckbox-root': {
      color: '#0EA554',
    },
    '& .Mui-checked': {
      color: '#24c776',
    },
  },
  checkBoxDisabledFirst: {
    '& .MuiCheckbox-root': {
      color: '#0EA554',
    },
    '& .checkBoxLabel': {
      color: 'white',
    },
  },
  checkBoxDisabled: {
    '& .MuiCheckbox-root': {
      color: '#0EA554',
    },
    '& .checkBoxLabel': {
      color: 'rgba(255,255,255,0.5)',
    },
  },
  checkBoxDisabledCoachDone: {
    '& .checkBoxLabel': {
      color: 'rgba(255,255,255,0.5)',
    },
    '& .MuiCheckbox-root': {
      color: '#24c776',
    },
  },
  checkBoxDisabledCoach: {
    '& .checkBoxLabel': {
      color: 'rgba(255,255,255,0.5)',
    },
    '& .MuiCheckbox-root': {
      color: '#0EA554',
    },
  },
  taskFinished: {
    opacity: 0.5,
  },
  toolsAndTask: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '20px',
    marginBottom: '40px',
  },
  toolsMaterial: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: '10px',
  },
  toolsMaterialVideo: {
    height: '420px',
  },
  textAreaContainer: {
    display: 'grid',
    gridTemplateRows: '1fr',
    backgroundColor: '#7b7b78',
    borderRadius: '5px',
    margin: '20px 0',
    padding: '16px',
  },
  reflection: {},
  reflectionRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  reflectionSaveBtn: {
    color: theme.palette.common.white,
    textTransform: 'none',
    '&.MuiButton-root:hover': {
      backgroundColor: '#9d9d9d',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  iconArrowForward: {
    fontSize: 18,
    cursor: 'pointer',
    backgroundColor: '#24c776',
    borderRadius: '50%',
    marginLeft: '5px',
    padding: '5px',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
  },
}));

type Props = {
  onChange: Function;
  isClient: boolean;
  email: string;
  submoduleDone: boolean;
  moduleId: number;
  toolId: number;
  submoduleId: number;
  lastSubmodule: boolean;
  taskProgress: Array<boolean>;
};

export const Task: FC<Props> = ({
  onChange,
  isClient,
  email,
  submoduleDone,
  moduleId,
  toolId,
  submoduleId,
  lastSubmodule,
  taskProgress,
}) => {
  const classes = useStyles();
  const [conditionOne, setConditionOne] = useState(false);
  const [conditionTwo, setConditionTwo] = useState(false);
  const [conditionThree, setConditionThree] = useState(false);
  const [conditionFour, setConditionFour] = useState(false);
  const [submitCondition, setSubmitCondition] = useState(false);
  const [taskFinished, setTaskFinished] = useState(submoduleDone);
  const [reflectionDescription, setReflectionDescription] = useState('');
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [reflectionsData, setReflectionsData] =
    useState<ReflectionsType | null>(null);
  const [toolData, setToolData] = useState<ToolType | null>(null);

  useEffect(() => {
    if (taskProgress) {
      setConditionOne(taskProgress[0]);
      setConditionTwo(taskProgress[1]);
      setConditionThree(taskProgress[2]);
      setConditionFour(taskProgress[3]);
    }
    getToolData(toolId);

    if (isClient || (!isClient && submoduleDone)) getReflectionsData();
  }, []);

  const getToolData = async (toolId: number) => {
    let payload = {
      id: toolId,
    };

    try {
      const res = await getToolDataAPI(payload);
      setToolData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getReflectionsData = async () => {
    const payload = {
      email: email,
      course: 1,
      submodule: submoduleId,
      module: moduleId,
    };

    try {
      const res = await getReflectionsAPI(payload);
      setReflectionsData(res.data);
      setReflectionDescription(res.data.reflections);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionOne(event.target.checked);

    setProgressAndSave(event.target.checked, false, false, false);

    if (conditionTwo) {
      setConditionTwo(false);
    }
    if (conditionThree) {
      setConditionThree(false);
    }
    if (conditionFour) {
      setConditionFour(false);
    }
  };

  const handleChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionTwo(event.target.checked);

    setProgressAndSave(true, event.target.checked, false, false);

    if (conditionThree) {
      setConditionThree(false);
    }
    if (conditionFour) {
      setConditionFour(false);
    }
  };

  const handleChangeThree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionThree(event.target.checked);

    setProgressAndSave(true, true, event.target.checked, false);

    if (conditionFour) {
      setConditionFour(false);
    }
  };

  const handleChangeFour = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionFour(event.target.checked);
    setSubmitCondition(event.target.checked);
  };

  const handleChangeSubmission = (finished: boolean) => {
    setTaskFinished(finished);
    setConditionFour(finished);
    setProgressAndSave(true, true, true, finished);
    if (finished) {
      onChange(finished);
    }
  };

  const handleReflectionSubmission = async (reflection: string) => {
    setReadOnly(!readOnly);
    const payload = {
      email: email,
      course: 1,
      submodule: submoduleId,
      module: moduleId,
      reflections: reflection,
    };
    try {
      const res = await submitReflectionsAPI(payload);
      setReflectionDescription(res.data.reflections);
    } catch (err) {
      console.log(err);
    }
  };

  const setProgressAndSave = (
    checkOne: boolean,
    checkTwo: boolean,
    checkThree: boolean,
    checkFour: boolean
  ) => {
    sendProgress([checkOne, checkTwo, checkThree, checkFour]);
  };

  const sendProgress = async (progress: Array<boolean>) => {
    let payload = {
      email: email,
      submodule_id: submoduleId,
      task_progress: progress,
    };
    try {
      const res = await saveTaskProgressAPI(payload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Box className={classes.taskContainer}>
        <Box className={classes.toolsAndTask}>
          <ReadonlyTitleDescription
            title={`Beschreibung`}
            description={toolData?.task_description}
            data-testid="description"
          />
          <ReadonlyTitleDescription
            title={`Aufgaben`}
            description={toolData?.tool_description}
            data-testid="tasks"
          />
        </Box>
        <Box className={classes.toolsAndTask}>
          <ReadonlyTitleDescription
            title={`Materialien`}
            data-testid="materials"
            description={
              <Box>
                <Box className={classes.toolsMaterial}>{toolData?.name}</Box>
                <Box className={classes.toolsMaterialVideo}>
                  <IF condition={isURL(toolData?.link)}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={toolData?.link}
                      title="Video player"
                      frameBorder="0"
                      allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </IF>
                  <IF condition={!isURL(toolData?.link)}>
                    <Box className={classes.description}>
                      Keine Materialien verfügbar
                    </Box>
                  </IF>
                </Box>
              </Box>
            }
          />
          <Box>
            <Box className={classes.heading} data-testid="success-levels-title">
              Erfolgsstufen
            </Box>
            <Box
              className={classes.description}
              data-testid="success-levels-description"
            >
              Bitte bestätige die Stufen, die Du bereits erreicht hast.
            </Box>
            <Box className={classes.checkBoxContainer}>
              <FormGroup className={classes.checkBoxFormGroup} row>
                <FormControlLabel
                  className={`
                        ${
                          conditionOne && isClient
                            ? classes.checkBoxEnabled
                            : !conditionOne && isClient
                            ? classes.checkBoxDisabledFirst
                            : !conditionOne && !isClient
                            ? classes.checkBoxDisabledCoach
                            : classes.checkBoxDisabledCoachDone
                        }
                        
                        ${taskFinished ? classes.taskFinished : ''}`}
                  labelPlacement="top"
                  control={
                    <Checkbox
                      data-testid={toolData?.task?.[0]}
                      checked={conditionOne}
                      disabled={taskFinished || !isClient}
                      onChange={handleChangeOne}
                      style={{
                        transform: 'scale(1.5)',
                      }}
                    />
                  }
                  label={
                    <div className="checkBoxLabel">{toolData?.task?.[0]}</div>
                  }
                />
                <FormControlLabel
                  className={`
                        ${
                          conditionOne
                            ? classes.checkBoxEnabled
                            : classes.checkBoxDisabled
                        }
                        ${taskFinished ? classes.taskFinished : ''}`}
                  labelPlacement="top"
                  disabled={
                    conditionOne && !taskFinished && isClient ? false : true
                  }
                  control={
                    <Checkbox
                      data-testid={toolData?.task?.[1]}
                      checked={conditionTwo}
                      onChange={handleChangeTwo}
                      style={{
                        transform: 'scale(1.5)',
                      }}
                    />
                  }
                  label={
                    <div className="checkBoxLabel">{toolData?.task?.[1]}</div>
                  }
                />
                <FormControlLabel
                  className={`
                        ${
                          conditionTwo
                            ? classes.checkBoxEnabled
                            : classes.checkBoxDisabled
                        }
                        ${taskFinished ? classes.taskFinished : ''}`}
                  labelPlacement="top"
                  disabled={
                    conditionOne && conditionTwo && !taskFinished && isClient
                      ? false
                      : true
                  }
                  control={
                    <Checkbox
                      data-testid={toolData?.task?.[2]}
                      checked={conditionThree}
                      onChange={handleChangeThree}
                      style={{
                        transform: 'scale(1.5)',
                      }}
                    />
                  }
                  label={
                    <div className="checkBoxLabel">{toolData?.task?.[2]}</div>
                  }
                />
                <FormControlLabel
                  className={`
                        ${
                          conditionThree
                            ? classes.checkBoxEnabled
                            : classes.checkBoxDisabled
                        }
                        ${taskFinished ? classes.taskFinished : ''}`}
                  labelPlacement="top"
                  disabled={
                    conditionOne &&
                    conditionTwo &&
                    conditionThree &&
                    !taskFinished &&
                    isClient
                      ? false
                      : true
                  }
                  control={
                    <Checkbox
                      data-testid={toolData?.task?.[3]}
                      checked={conditionFour}
                      onChange={handleChangeFour}
                      style={{
                        transform: 'scale(1.5)',
                      }}
                    />
                  }
                  label={
                    <div className="checkBoxLabel">{toolData?.task?.[3]}</div>
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        </Box>
        <Box className={classes.reflection}>
          <ReadonlyTitleDescription
            title={`Meine Reflexion`}
            description={
              <Box className={classes.textAreaContainer}>
                <EditableTextArea
                  data-testid="editable-textarea-praxis"
                  readOnly={readOnly}
                  textContent={reflectionDescription}
                  backgroundColor={'#7b7b78'}
                  onChange={(e) => setReflectionDescription(e.target.value)}
                />
                <Box className={classes.reflectionRightContainer}>
                  {isClient && !submoduleDone && (
                    <Box className={classes.btnContainer}>
                      {readOnly === true ? (
                        <Button
                          data-testid="edit-praxis"
                          onClick={() => setReadOnly(!readOnly)}
                          className={classes.reflectionSaveBtn}
                        >
                          <Typography>bearbeiten</Typography>
                          <EditIcon className={classes.iconArrowForward} />
                        </Button>
                      ) : (
                        <Button
                          data-testid="save-praxis"
                          onClick={() =>
                            handleReflectionSubmission(reflectionDescription)
                          }
                          className={classes.reflectionSaveBtn}
                        >
                          <Typography>speichern</Typography>
                          <CloudUploadIcon
                            className={classes.iconArrowForward}
                          />
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
      <IF condition={conditionFour && submitCondition}>
        <SubmissionConfirmation
          onChange={handleChangeSubmission}
          lastSubmodule={lastSubmodule}
        />
      </IF>
    </Fragment>
  );
};

export default Task;
